
.title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #000000A6;
    margin-bottom: 1rem;
}

/*最多只显示2行，超过2行用省略号显示*/
.content{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.footer-sign{
    font-size: 14px;
    line-height: 22px;
    font-weight: 100;
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}


/*新闻简介*/
/*最多只显示2行，超过2行用省略号显示*/
.news-description-content{
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

/*新闻news-box卡片样式*/
.news-card {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s;
    background: #F5F5F5;
}
/*新闻news-box卡片样式*/
.news-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.news-card:active{
    transform: scale(0.99);
}
